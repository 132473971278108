// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ---------- LOGIN ---------- */
.login-wrapper {
    max-width: 400px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-wrapper h2 {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
}

.login-wrapper .form-group {
    margin-bottom: 1rem;
}

.login-wrapper .form-control {
    padding: 0.75rem;
    font-size: 1rem;
}

.login-wrapper .btn-primary {
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 500;
}

.login-wrapper .alert {
    margin-top: 1rem;
    font-size: 0.9rem;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;AAC3C;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["/* ---------- LOGIN ---------- */\r\n.login-wrapper {\r\n    max-width: 400px;\r\n    margin: 0 auto;\r\n    padding: 2rem;\r\n    background-color: #f8f9fa;\r\n    border: 1px solid #ddd;\r\n    border-radius: 4px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.login-wrapper h2 {\r\n    margin-bottom: 1.5rem;\r\n    font-weight: 500;\r\n    font-size: 1.5rem;\r\n    text-align: center;\r\n}\r\n\r\n.login-wrapper .form-group {\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n.login-wrapper .form-control {\r\n    padding: 0.75rem;\r\n    font-size: 1rem;\r\n}\r\n\r\n.login-wrapper .btn-primary {\r\n    padding: 0.75rem;\r\n    font-size: 1rem;\r\n    font-weight: 500;\r\n}\r\n\r\n.login-wrapper .alert {\r\n    margin-top: 1rem;\r\n    font-size: 0.9rem;\r\n    text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
