// src/App.js
import React, { useState } from 'react';
import Login from './components/Login';
import Dashboard from './pages/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('authToken')); // Check if there's a token in localStorage
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken') || ''); // Initialize authToken from localStorage if present

  const handleLogin = (token) => {
    localStorage.setItem('authToken', token); // Store token in localStorage
    setAuthToken(token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Clear token from localStorage
    setAuthToken('');
    setIsLoggedIn(false);
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4">
        {isLoggedIn ? (
          <Dashboard authToken={authToken} onLogout={handleLogout} />
        ) : (
          <Login onLogin={handleLogin} />
        )}
      </div>
    </div>
  );
}

export default App;
